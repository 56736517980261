//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'RkSiteWrapper',
  props: {
    tag: {
      type: String,
      default: `div`,
    },
    width: {
      type: String,
      default: '',
      validator(value) {
        return ['', 'slim', 'narrow', 'mid', 'outer'].includes(value);
      },
    },
  },
  setup() {
    const wrapperType = (width) => {
      switch (width) {
        case 'slim':
          return 'max-w-slim';
        case 'narrow':
          return 'max-w-narrow';
        case 'mid':
          return 'max-w-mid';
        case 'outer':
          return 'max-w-outer 2xl:px-12';
        default:
          return 'max-w-site';
      }
    };

    return {
      wrapperType,
    };
  },
});
